import React, { useContext, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Link,
  Typography,
} from '@material-ui/core';
import { blue, green, grey } from '@material-ui/core/colors';
import { DeleteForever } from '@material-ui/icons';

import { CircleCancel, theme } from '@konecorp/ui-library';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { formatDate } from '../../helpers/formating';
import { useGetToken } from '../../hooks/useGetToken';
import Context from '../../context';
import {
  API_TYPE,
  fetchContacts,
  fetchSubcontractors,
  fetchVendors,
  getEmployeesDataFromInstallation,
  post,
  put,
  remove,
  get,
} from '../../helpers/fetch';
import {
  ActivityDifferentiator,
  Assignment,
  Contact,
  ContactRole,
  Employee,
  ExtendedInstallation,
  Installation,
  isAssignment,
  RoleSource,
  Subcontractor,
  SubcontractorRecord,
  Vendor,
} from '../../schemas';

import ContactForm from '../ContactForm';
import { SubcontractorForm } from '../SubcontractorForm';
import AddTechnicianForm from '../../containers/AddTechnicianForm';
import ErrorMessageDialog from '../ErrorMessageDialog';
import AddButton from '../AddButton';

type Worker = Assignment | SubcontractorRecord;

export type TeamListProps = {
  installation: Installation | ExtendedInstallation;
  networkNumber: string;
};

type FilterWorkersReturnType = {
  installers: Worker[];
  testers: Worker[];
  serviceEngineers: Worker[];
};

export enum DialogType {
  NONE,
  SUBCONTRACTOR,
  CONTACT,
  ADD_TECHNICIAN,
  REMOVE,
}

const useStyles = makeStyles<Theme>(() => ({
  teamListContainer: {
    width: '100%',
  },
  headlineCommonStyle: {
    padding: theme.spacing(1),
    textTransform: 'uppercase',
  },
  greenHeadline: {
    backgroundColor: green[100],
    padding: theme.spacing(1),
    textTransform: 'uppercase',
  },
  blueHeadline: {
    backgroundColor: blue[100],
    padding: theme.spacing(1),
    textTransform: 'uppercase',
  },
  greyHeadline: {
    backgroundColor: grey[200],
    padding: theme.spacing(1),
    textTransform: 'uppercase',
  },
  assigmentInfo: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    '& p': {
      cursor: 'pointer',
    },
    textTransform: 'uppercase',
  },
  assignmentInfoName: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  assignmentDeleteIcon: {
    padding: 0,
  },
  sourceChipCommon: {
    borderStyle: 'solid',
    borderWidth: '1px',
    fontSize: '0.8em',
    fontWeight: 'bold',
    height: 'min-content',
    paddingLeft: theme.spacing(0.4),
    paddingRight: theme.spacing(0.4),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    marginRight: theme.spacing(0.5),
  },
  sourceChipLight: {
    borderColor: theme.palette.grey[400],
  },
  sourceChipDark: {
    borderColor: theme.palette.grey[700],
    backgroundColor: theme.palette.grey[700],
    color: theme.palette.grey[200],
  },
  closeDialogIcon: {
    width: 30,
    height: 30,
    position: 'absolute',
    zIndex: 1,
    right: theme.spacing(1.5),
    top: theme.spacing(1.5),
  },
}));

const TeamList = ({ installation, networkNumber }: TeamListProps): JSX.Element => {
  const { updateIsLoading, updateErrorMessage } = useContext(Context);
  const { t } = useTranslation();
  const classes = useStyles();
  const [getTokenFunction] = useGetToken();

  const [employees, setEmployees] = useState<Employee[]>([]);
  const [workers, setWorkers] = useState<Worker[]>([]);
  const [selectedItem, setSelectedItem] = useState<Worker | Contact | null>(null);
  const [vendors, setVendors] = useState<Vendor[]>([]);
  const [showDialog, setShowDialog] = useState<DialogType>(DialogType.NONE);
  const [technicianRole, setTechnicianRole] = useState<
    ActivityDifferentiator | undefined
  >(undefined);
  const [removeSubcontractorPopup, setRemoveSubcontractorPopup] =
    useState<boolean>(false);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [potentialKoneAssignees, setPotentialKoneAssignees] = useState<
    Employee[] | undefined
  >(undefined);
  const [itemToRemove, setItemToRemove] = useState<Worker | Contact | undefined>();
  const [errorMsg, setErrorMsg] = useState(false);

  const fetchVendorsForInstallation = async (
    installation: Installation | ExtendedInstallation,
    accessToken: string
  ) => {
    const vendorsCountryKey = installation.customer?.countryKey;
    return vendorsCountryKey ? await fetchVendors(vendorsCountryKey, accessToken) : [];
  };

  useEffect(() => {
    (async () => {
      updateIsLoading(true);
      try {
        const accessToken = await getTokenFunction();

        const [employees, subcontractors, vendors, contacts] = await Promise.all([
          getEmployeesDataFromInstallation(accessToken, installation as Installation),
          fetchSubcontractors(networkNumber, accessToken),
          fetchVendorsForInstallation(installation, accessToken),
          fetchContacts(networkNumber, accessToken),
        ]);

        const workers = [...(installation.assignees ?? []), ...subcontractors];

        setEmployees(
          employees.filter((employee): employee is Employee => employee !== null)
        );
        setWorkers(workers);
        setVendors(vendors);
        setContacts(contacts);
      } catch (error) {
        updateErrorMessage({ message: t('teamList.cannotGetRequiredData'), error });
      } finally {
        updateIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (technicianRole) {
        try {
          updateIsLoading(true);
          const companyCode = installation.companyCode || '';

          const token = await getTokenFunction();
          const employeesData: Employee[] = await get(
            `v1/employees?role=${technicianRole}&companyCode=${companyCode}`,
            token
          );
          setPotentialKoneAssignees(employeesData);
        } catch (error) {
          updateErrorMessage({
            message: t('teamList.cannotGetKoneEmployeeData'),
            error,
          });
        } finally {
          updateIsLoading(false);
        }
      }
    })();
  }, [technicianRole]);

  const filterWorkersByRole = (workers: Worker[]): FilterWorkersReturnType => {
    const byRole = (role: ActivityDifferentiator) => (worker: Worker) =>
      worker.activityDifferentiator === role;
    return {
      installers: workers.filter(byRole(ActivityDifferentiator.INST)),
      testers: workers.filter(byRole(ActivityDifferentiator.CMSN)),
      serviceEngineers: workers.filter(byRole(ActivityDifferentiator.SEEN)),
    };
  };

  const handleAddTechnician = (activityDifferentiator: ActivityDifferentiator) => {
    setTechnicianRole(activityDifferentiator);
    setShowDialog(DialogType.ADD_TECHNICIAN);
    setSelectedItem(null);
  };

  const closeTechnicanForm = () => {
    setTechnicianRole(undefined);
    setPotentialKoneAssignees(undefined);
    setShowDialog(DialogType.NONE);
  };

  const createSubcontractor = async (
    subcontractor: Subcontractor,
    pincode: string,
    plannedStartDate: string,
    plannedEndDate: string
  ) => {
    const accessToken = await getTokenFunction();

    try {
      updateIsLoading(true);
      await post(
        `v1/subcontractor/${networkNumber}/access`,
        accessToken,
        API_TYPE.APPLICATION,
        {
          ...subcontractor,
          pincode,
          plannedStartDate,
          plannedEndDate,
          activityDifferentiator: technicianRole,
        }
      );
      //TODO: maybe the POST request above should return the new subcontractor object instead of fetching everything again
      const subcontractors = await fetchSubcontractors(networkNumber, accessToken);
      const currentWorkerWithoutSubcontractor = workers.filter(isAssignment);
      setWorkers([...currentWorkerWithoutSubcontractor, ...subcontractors]);
      closeTechnicanForm();
      updateIsLoading(false);
    } catch (error) {
      updateErrorMessage({ message: t('teamList.cannotSaveSubcontractor'), error });
      updateIsLoading(false);
    }
  };

  const createAssignee = async (
    employeeId: string,
    assignmentStartDate: string,
    assignmentEndDate: string
  ) => {
    const accessToken = await getTokenFunction();

    try {
      updateIsLoading(true);
      const newAssignee: Assignment = await put(
        `v1/installations/${networkNumber}/assignee`,
        accessToken,
        API_TYPE.APPLICATION,
        {
          assigneeId: employeeId,
          userRole: technicianRole,
          assignmentStartDate,
          assignmentEndDate,
        }
      );

      const newEmployeeInfo = potentialKoneAssignees?.find(
        (potentialAssignee) =>
          potentialAssignee.employeeId === newAssignee.koneResourcePersonalNumber
      );
      if (newEmployeeInfo) {
        setEmployees([...employees, newEmployeeInfo]);
      }
      setWorkers([...workers, newAssignee]);
      closeTechnicanForm();
      setShowDialog(DialogType.NONE);

      updateIsLoading(false);
    } catch (error) {
      updateErrorMessage({ message: t('teamList.cannotSaveAssignee'), error });
      updateIsLoading(false);
    }
  };

  const removeSubcontractor = async (activityDifferentiator: string) => {
    try {
      updateIsLoading(true);
      const accessToken = await getTokenFunction();

      await remove(
        `v1/subcontractor/${networkNumber}/access/${activityDifferentiator}`,
        accessToken,
        API_TYPE.APPLICATION
      );

      const updatedWorkers = workers.filter(
        (worker) =>
          isAssignment(worker) || worker.activityDifferentiator !== activityDifferentiator
      );

      setRemoveSubcontractorPopup(false);
      setShowDialog(DialogType.NONE);
      setWorkers(updatedWorkers);
      updateIsLoading(false);
    } catch (error) {
      updateIsLoading(false);
      updateErrorMessage({ message: t('teamList.cannotRemoveSubcontractor'), error });
      setRemoveSubcontractorPopup(false);
    }
  };

  const removeItem = async (item: Worker) => {
    try {
      updateIsLoading(true);
      const accessToken = await getTokenFunction();

      const url = isAssignment(item)
        ? `v1/installations/${networkNumber}/assignees/${item.koneResourcePersonalNumber}`
        : `v1/subcontractor/${networkNumber}/access/${item.activityDifferentiator}`;

      await remove(url, accessToken, API_TYPE.APPLICATION);

      const updatedWorkers = workers.filter((worker) =>
        isAssignment(item)
          ? !isAssignment(worker) ||
            worker.koneResourcePersonalNumber !== item.koneResourcePersonalNumber
          : isAssignment(worker) ||
            worker.activityDifferentiator !== item.activityDifferentiator
      );

      setShowDialog(DialogType.NONE);
      setWorkers(updatedWorkers);
      updateIsLoading(false);
    } catch (error) {
      updateIsLoading(false);
      updateErrorMessage({ message: t('teamList.cannotRemoveSubcontractor'), error });
    }
  };

  const sendContact = async (contactToSend: Contact) => {
    const token = await getTokenFunction();

    try {
      updateIsLoading(true);
      const returnedContact: Contact = await put(
        `v1/installations/${networkNumber}/contacts`,
        token,
        API_TYPE.APPLICATION,
        contactToSend
      );

      setShowDialog(DialogType.NONE);
      setSelectedItem(null);

      const updatedContacts =
        contactToSend.guid !== returnedContact.guid
          ? [...contacts, returnedContact]
          : contacts.map((contact) =>
              contact.guid === returnedContact.guid ? returnedContact : contact
            );
      setContacts(updatedContacts);
    } catch (error) {
      updateErrorMessage({ message: t('teamList.cannotSaveContact'), error });
    } finally {
      updateIsLoading(false);
    }
  };

  const deleteContact = async (contact: Contact) => {
    const token = await getTokenFunction();

    try {
      updateIsLoading(true);
      await remove(
        `v1/installations/${networkNumber}/contacts/${contact.guid}`,
        token,
        API_TYPE.APPLICATION
      );
      const updatedContacts = contacts.filter((c) => c.guid !== contact.guid);
      setContacts(updatedContacts);
      setShowDialog(DialogType.NONE);
    } catch (error) {
      updateErrorMessage({ message: t('teamList.cannotSaveContact'), error });
    } finally {
      updateIsLoading(false);
    }
  };

  const getEmployeeName = (employeeId: string) => {
    const employee = employees.find((employee) => employee.employeeId === employeeId);
    return employee ? `${employee.legalFirstName} ${employee.legalLastName}` : employeeId;
  };

  const EmployeeName = (props: { assignee: Assignment }): JSX.Element => {
    const name = getEmployeeName(props.assignee.koneResourcePersonalNumber);
    return <Typography>{name}</Typography>;
  };

  const SubcontractorName = (props: {
    subcontractor: SubcontractorRecord;
  }): JSX.Element => {
    const { subcontractor } = props;
    return (
      <Typography>
        <Link
          onClick={() => {
            setSelectedItem(subcontractor);
            setShowDialog(DialogType.SUBCONTRACTOR);
          }}
        >
          {subcontractor.subcontractor.name}
        </Link>
      </Typography>
    );
  };

  const SourceChip = (props: { source: RoleSource }): JSX.Element => {
    const { source } = props;
    const text = t(`teamList.source.${source}`);
    const clazz =
      source === RoleSource.IRMA ? classes.sourceChipLight : classes.sourceChipDark;

    return <Box className={`${classes.sourceChipCommon} ${clazz}`}>{text}</Box>;
  };

  const AssigmentInfo = (props: { worker: Worker }): JSX.Element => {
    const { worker } = props;

    const [startDateRaw, endDateRaw, source] = isAssignment(worker)
      ? [worker.assignmentStartDate, worker.assignmentEndDate, worker.source]
      : [worker.plannedStartDate, worker.plannedEndDate, RoleSource.SUPERVISOR];

    const startDate = endDateRaw
      ? formatDate(startDateRaw, 'dd.MM')
      : formatDate(startDateRaw);
    const endDate = endDateRaw ? formatDate(endDateRaw) : 'Not available';

    return (
      <Grid container className={classes.assigmentInfo}>
        <Grid item xs={6}>
          <Box className={classes.assignmentInfoName}>
            <SourceChip source={source} />
            {isAssignment(worker) ? (
              <EmployeeName assignee={worker} />
            ) : (
              <SubcontractorName subcontractor={worker} />
            )}
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Typography>{`${startDate} - ${endDate}`}</Typography>
        </Grid>
        <Grid item xs={1} container justify="flex-end" alignContent="flex-start">
          <IconButton
            data-testid={`remove-icon-${
              isAssignment(worker) ? 'assignee' : 'subcontractor'
            }-${worker.activityDifferentiator.toLowerCase()}`}
            className={classes.assignmentDeleteIcon}
            onClick={() => {
              setItemToRemove(worker);
              setShowDialog(DialogType.REMOVE);
            }}
          >
            <DeleteForever />
          </IconButton>
        </Grid>
      </Grid>
    );
  };

  const ContactInfo = (props: { contact: Contact }): JSX.Element => {
    const { contact } = props;
    return (
      <Grid container className={classes.assigmentInfo}>
        <Grid item xs={5}>
          <Typography>{t(`contactRoles.${contact.role}`)}</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography>
            {' '}
            {/*  we can add align right to be as previous  */}
            <Link
              onClick={() => {
                setSelectedItem(contact);
                setShowDialog(DialogType.CONTACT);
              }}
            >
              {contact.firstName && contact.lastName
                ? `${contact.firstName} ${contact.lastName}`
                : contact.firstName || contact.lastName || '-'}
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={2} container justify="flex-end" alignContent="flex-end">
          <IconButton
            data-testid={`remove-icon-contact-${contact.guid}`}
            className={classes.assignmentDeleteIcon}
            onClick={() => {
              setItemToRemove(contact);
              setShowDialog(DialogType.REMOVE);
            }}
          >
            <DeleteForever />
          </IconButton>
        </Grid>
      </Grid>
    );
  };

  const RemoveDialog = (props: {
    item: Worker | Contact;
    onRemove: (item: Worker) => void;
    onCancel: () => void;
  }): JSX.Element => {
    const { item, onRemove, onCancel } = props;
    const isWorker = (item: Worker | Contact): item is Worker => {
      return (item as Worker).activityDifferentiator !== undefined;
    };
    return (
      <>
        <DialogContent
          style={{ paddingTop: theme.spacing(6), paddingBottom: theme.spacing(2) }}
        >
          <Typography align="center" style={{ fontWeight: 'bold' }} gutterBottom>
            {isWorker(item)
              ? t('teamList.remove.title', {
                  name: isAssignment(itemToRemove)
                    ? getEmployeeName(itemToRemove.koneResourcePersonalNumber)
                    : (itemToRemove as SubcontractorRecord)?.subcontractor.name,
                })
              : t('teamList.remove.titleContact', {
                  name:
                    `${(item as Contact).firstName || ''} ${
                      (item as Contact).lastName || ''
                    }`.trim() || '-',
                })}
          </Typography>
          <Typography align="center" style={{ fontWeight: 'bold' }}>
            {t('teamList.remove.question')}
          </Typography>
        </DialogContent>
        <DialogActions
          style={{ padding: theme.spacing(2), paddingBottom: theme.spacing(3) }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              isWorker(item) ? onRemove(item as Worker) : deleteContact(item)
            }
            fullWidth
          >
            {t('teamList.remove.confirm')}
          </Button>
          <Button variant="contained" onClick={() => onCancel()} autoFocus fullWidth>
            {t('teamList.remove.cancel')}
          </Button>
        </DialogActions>
      </>
    );
  };
  const initialContact: Contact = {
    role: ContactRole.BUILDER,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    guid: '',
  };
  const contactButtons = [
    {
      label: t('teamList.addCustomer'),
      dialogType: DialogType.CONTACT,
      initialContact: { ...initialContact, role: ContactRole.CUSTOMER },
    },
    {
      label: t('teamList.addCSE'),
      dialogType: DialogType.CONTACT,
      initialContact: {
        ...initialContact,
        role: ContactRole.CSE,
      },
    },
    {
      label: t('teamList.addOtherContact'),
      dialogType: DialogType.CONTACT,
      initialContact: { ...initialContact, role: ContactRole.BUILDER },
    },
  ];
  const TeamList = (): JSX.Element => {
    const { installers, testers, serviceEngineers } = filterWorkersByRole(workers);

    const installerTotalHours = Math.ceil(Number(installation.installerHours)) || 0;
    const testerTotalHours = Math.ceil(Number(installation.testerHours)) || 0;
    const installationTotalHours =
      Math.ceil(Number(installation.totalInstallationHours)) || 0;
    const handleAddContact = (initialContact: Contact) => {
      if (contacts.length >= 10) {
        setErrorMsg(true);
        return;
      }
      setSelectedItem(initialContact);
      setShowDialog(DialogType.CONTACT);
    };
    return (
      <>
        <Grid item xs={12}>
          <Typography className={classes.greenHeadline} component="div">
            {t('teamList.installer')}
          </Typography>
          {installers.map((worker, index) => (
            <AssigmentInfo worker={worker} key={index} />
          ))}
          <AddButton
            label={`+ ${t('teamList.addInstaller')}`}
            onClick={() => handleAddTechnician(ActivityDifferentiator.INST)}
          />

          <Typography paragraph={true} align="right">
            {t('teamList.totalHours', { hours: installerTotalHours })}
          </Typography>

          <Typography className={classes.blueHeadline} component="div">
            {t('teamList.tester')}
          </Typography>
          {testers.map((worker, index) => (
            <AssigmentInfo worker={worker} key={index} />
          ))}
          <AddButton
            label={`+ ${t('teamList.addTester')}`}
            onClick={() => handleAddTechnician(ActivityDifferentiator.CMSN)}
          />

          <Typography
            paragraph={true}
            align="right"
            style={{ textTransform: 'uppercase' }}
          >
            {t('teamList.totalHours', { hours: testerTotalHours })}
          </Typography>

          {serviceEngineers.length > 0 && (
            <>
              <Typography className={classes.greenHeadline} component="div">
                {t('teamList.serviceEngineer')}
              </Typography>
              {serviceEngineers.map((worker, index) => (
                <AssigmentInfo worker={worker} key={index} />
              ))}
            </>
          )}

          <Typography className={classes.greyHeadline} component="div">
            {t('teamList.contacts')}
          </Typography>
          {contacts?.map((contact, index) => (
            <ContactInfo contact={contact} key={index} />
          ))}
          {contactButtons.map((button, index) => (
            <AddButton
              key={index}
              label={`+ ${button.label}`}
              onClick={() => {
                handleAddContact(button.initialContact);
              }}
            />
          ))}
          <ErrorMessageDialog
            editValue={errorMsg}
            handleEditChange={setErrorMsg}
            typeOfError="contact"
          />
          <Typography className={classes.greyHeadline} component="div">
            {t('teamList.installationTotalHours', {
              hours: installationTotalHours,
            })}
          </Typography>
        </Grid>
      </>
    );
  };

  return (
    <div className={classes.teamListContainer}>
      <TeamList />
      <Dialog
        data-testid="dialog-form"
        maxWidth="sm"
        fullWidth
        open={showDialog !== DialogType.NONE}
        scroll="paper"
      >
        <CircleCancel
          className={classes.closeDialogIcon}
          onClick={() => {
            setShowDialog(DialogType.NONE);
            setSelectedItem(null);
          }}
        />

        {showDialog === DialogType.SUBCONTRACTOR && (
          <SubcontractorForm
            showSubcontractorModal={showDialog === DialogType.SUBCONTRACTOR}
            subcontractor={selectedItem as SubcontractorRecord}
            vendors={vendors}
            setShowSubcontractorModal={(isOpen) =>
              setShowDialog(isOpen ? DialogType.SUBCONTRACTOR : DialogType.NONE)
            }
            onSave={createSubcontractor}
            onRemove={removeSubcontractor}
            setRemoveSubcontractorPopup={setRemoveSubcontractorPopup}
            removeSubcontractorPopup={removeSubcontractorPopup}
          />
        )}

        {showDialog === DialogType.CONTACT && (
          <DialogContent style={{ padding: 0 }}>
            <ContactForm
              contactToEdit={(selectedItem || undefined) as Contact | undefined}
              onSubmit={sendContact}
              onClose={() => setShowDialog(DialogType.NONE)}
            />
          </DialogContent>
        )}
        {showDialog === DialogType.ADD_TECHNICIAN &&
          potentialKoneAssignees &&
          technicianRole && (
            <DialogContent style={{ padding: 0 }}>
              <AddTechnicianForm
                installation={installation as Installation}
                potentialKoneAssignees={potentialKoneAssignees}
                role={technicianRole}
                vendors={vendors}
                createSubcontractor={createSubcontractor}
                createAssignee={createAssignee}
                onClose={closeTechnicanForm}
              />
            </DialogContent>
          )}
        {showDialog === DialogType.REMOVE && itemToRemove && (
          <RemoveDialog
            item={itemToRemove}
            onRemove={removeItem}
            onCancel={() => setShowDialog(DialogType.NONE)}
          />
        )}
      </Dialog>
    </div>
  );
};

export default TeamList;
